.site-header {
  width: 100%;
  background-color: $base-white;
  padding: 20px 0;

  &__container {
    position: relative;
  }

  &__wrapper {
    display: flex;
  }

  &__searchbar {
    width: 100%;
    display: flex;
    transition: all 0.5s ease;
    align-items: center;

    @include touch {
      display: none;
    }
  }

  .hamburger-icon {
    display: none;
    width: 18%;


    .action {
      right: 25px;
      position: fixed;
      display: block;
      margin: 0;
      padding: 0;
      cursor: pointer;
      border: none;
      left: 0;
      top: 12px;
      width: 48px;
      height: 48px;
      background: none;
      z-index: 999;
    }

    .navTrigger {
      width: 42px;
      height: 42px;
      position: fixed;
      left: 0;
      top: 19px;
      z-index: 700;
      bottom: 0;
      border-radius: 100px;
      padding: 14px 12px;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;

      i {
        background-color: #000;
        border-radius: 50px;
        content: '';
        display: block;
        width: 26px;
        height: 3px;

        &:nth-child(1) {
          animation: outT 0.8s backwards;
          animation-direction: reverse;
        }

        &:nth-child(2) {
          margin: 4px 0;
          animation: outM 0.8s backwards;
          animation-direction: normal;
          animation-direction: reverse;
        }

        &:nth-child(3) {

          animation: outT 0.8s backwards;
          animation-direction: reverse;
        }
      }
    }

    @include touch {
      display: block;
    }
  }

  &__branding {
    width: 100%;

    &-logo {
      display: flex;
      align-items: center;
      font-size: 35px;
      color: $base-primary;
      font-weight: bold;
      transition: all .5s ease;

      svg {
        width: 220px;
        height: auto;

        @include mobile {
          width: 175px;
        }
      }

      span {
        font-size: 25px;
        color: $base-secondary;
          
        @include touch {
          font-size: 16px;
        }
      }

      @include touch {
        font-size: 26px;
      }
    }

    @include mobile {
      display: flex;
      align-items: center;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .actions__menu {
      display: flex;
      padding: 0;
      margin: 0;

      &-item {
        list-style: none;
        position: relative;

        a {
          text-decoration: none;
          color: $base-secondary;

          i {
            font-size: 1.6875rem;
            margin: 0 30px 0 0;
            transition: all .5s ease;

            @include mobile {
              margin: 0 10px 0 0;
            }
          }

          &:hover {
            i {
              color: $base-primary;
            }
          }
        }
      }

      &-button {
        background-color: $base-primary;
        padding: 11px 27px;
        margin: 0 60px 0 0;
        transition: all .5s ease;

        a {
          font-size: 13px;
          font-weight: bold;
        }

        &:hover {
          background-color: $base-secondary;

          a {
            color: $base-white;
          }
        }
      }

      &-count {
        background-color: $base-primary;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 0.625rem;
        font-weight: bold;
      }

      &-shopcount {
        width: 100%;
        display: block;
        height: 100%;
        position: absolute;
        top: -6px;
        right: -28px;
      }
    }

    @include touch {
      width: auto;
    }
  }
}