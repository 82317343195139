.choicesize {
    .clicked {
        display: none;
    }
    &__form {

        &-inner {
            display: flex;

            @include mobile {
                display: block;
            }
        }
    }

    &__heading {
        margin-right: 0.625rem;
        
        &-title {
            font-weight: 500;
            font-size: 1.1rem;

            @include mobile {
                font-size: 1rem;
            }
        }

        &-requirements {
            font-size: 0.8rem;
            color: $base-grey;
            font-weight: bold;
        }
    }

    &__inputs {
        display: flex;
        position: relative;
        align-items: center;
    }
}