.site-header-mobile {
    position: fixed;
    background-color: $base-white;
    width: 100%;
    left: 0;
    top: 61px;
    z-index: 99;
    overflow-y: scroll;
    height: 100%;

    .navigation__menu {
        list-style: none;
        color: $base-white;

        .open {
            display: block;
            padding: 1rem 1rem;
        }

        .closed {
            display: none;
        }
        
        &-item {
            border-bottom: 1px solid $base-secondary;
            padding: 1rem 0;

            .active {
                font-weight: 500;
                width: 100%;
                display: block;
                border-bottom: 1px solid black;
            }
        }

        &-link {
            color: $base-secondary;
            padding: 0rem 1rem 1rem 1rem;
        }

        .menu__hover {
            margin-bottom: 0.2rem;

            &-title {
                margin-bottom: 1rem;
                color: $base-secondary;
                font-weight: 500;
            }

            &-link {
                color: $base-secondary;
            }
        }
    }
}