.choiceoption {
    margin-bottom: 2rem;

    .selected {
        border: 2px solid $base-secondary;

        .choiceoption__heading-title {
            color: $base-secondary;
        }
    }

    .deselected {
        cursor: pointer;
    }

    .choiceaccessoires__form-inner {
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            flex-direction: row;
            overflow-x: auto;
        }
    }
    
    &__form {
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            flex-direction: row;
            overflow-x: auto;
        }
    }
    &__heading {
        width: 200px;
        border: 2px solid #D1D1D1;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        margin-bottom: 30px;
        border-radius: 10px;
        transition: all .5s ease;

        &-img {
            border-radius: 10px 10px 0 0;
        }

        &-no {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-content {
            padding: 0 1rem 1rem 1rem;
        }

        &-empty {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        &-title {
            font-size: 1rem;
            font-weight: bold;
            color: #6b6b6b;
            transition: all .3s ease;

            @include mobile {
                font-size: 0.8rem;
            }
        }

        &-subtitle {
            font-size: 0.6rem;
            color: #6b6b6b;
            font-weight: 500;
        }

        &-amount {
            display: flex;
            height: 126px;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
        }

        &-count {
            margin: 0 14px;
        }

        &-none {

        }

        &:hover {
            border: 2px solid $base-secondary;

            .choiceoption__heading-title {
                color: $base-secondary;
            }
        }

        @include mobile {
            width: 200px;
            height: auto;
            flex: 0 0 auto;
            margin-right: 15px;
        }
    }
}