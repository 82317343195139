.pagecontact {
    display: flex;

    &__content {
        width: 50%;
        margin-right: 20px;
        
        @include mobile {
            margin: 0;
            width: 100%;
        }
    }

    &__form {
        width: 50%;

        @include mobile {
            width: 100%;
        }
    }

    @include mobile {
        display: block;
    }
}