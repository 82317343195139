.inactive {
    height: 40px;
    cursor: pointer;

    .configurator__choice-card {
        display: none;
    }

    .choice__button {
        display: none;
    }

    .choicesummary__heading {
        margin-bottom: 0;
    }

    .choicesummary__heading-subtitle {
        display: none;
    }

    .choice__heading {
        @include mobile {
            margin-bottom: 0!important;
            align-items: center;
        }
    }

    .choiceoption {
        @include mobile {
            margin-bottom: 0;
        }
    }

    @include mobile {
        height: initial;
    }
}

.configurator {

    &__heading {
        display: inline-block;
        border-bottom: 3px solid $base-secondary;
        margin-bottom: 1rem;

        &-title {
            font-size: 1.7rem;
            font-weight: 500;

            @include mobile {
                font-size: 1.6rem;
            }
        }

        @include mobile {
            margin-bottom: 1.5rem;
        }
    }

    &__choice {
        width: 100%;
        background-color: $base-white;
        padding: 1rem;
        border-radius: 0.625rem;
        margin-bottom: 1rem;
        box-shadow: 0px 0px 22px 0px rgba(#8B8B8B,0.1);

        .choice__heading {
            display: flex;

            &-title {
                font-size: 1.6rem;
                font-weight: 500;
                margin-left: 1.25rem;

                @include mobile {
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                }
            }

            &-number {
                background-color: $base-primary;
                border-radius: 50%;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    font-size: 1.4rem;
                    font-weight: 500;

                    @include mobile {
                        font-size: 0.9rem;
                    }
                }

                @include mobile {
                    width: 26px;
                    height: 26px;
                }
            }

            &-closebutton {
                margin: 0 0 0 auto;
                display: flex;

                i {
                    font-size: 39px;
                    margin-left: 33px;

                    @include mobile {
                        font-size: 25px;
                        margin: 0;
                    }
                }
            }

            &-option {
                display: flex;
            }

            &-result {
                margin-left: 10px;
                display: flex;

                i {
                    margin-right: 11px;
                }

                .result__text {
                    font-weight: 500;
                    font-size: 1.6rem;

                    &-amount {
                        font-weight: 500;
                        font-size: 1.6rem;
                        margin-left: 5px;

                        @include mobile {
                            font-size: 0.9rem;
                        }
                    }

                    &-size { 
                        font-weight: 500;
                        font-size: 1.6rem;

                        &:last-of-type {
                            span {
                                display: none;
                            }
                        }

                        @include mobile {
                            font-size: 0.9rem;
                        }
                    }

                    @include mobile {
                        font-size: 0.9rem;
                    }
                }

                .result__wrapper {
                    display: flex;
                    align-items: center;

                    &:nth-child(3) {
                        .result__wrapper-value {
                            .result__wrapper-separator {
                                display: none;
                            }
                        }
                    }

                    &-value {
                        font-weight: 500;
                    }

                    &-separator {
                        margin: 0 5px;
                    }
                }

                .result__unit {
                    margin-left: 3px;
                    font-weight: 500;
                }

                .result__empty {
                    display: flex;
                    align-items: center;
                }
            }

            @include mobile {     
                margin-bottom: 0.5rem;
                align-items: center;
            }
        }

        .choice__button {
            padding: 8px 22px;

            span {
                font-size: 1rem;
                font-weight: bold;

                @include mobile {
                    font-size: 0.8rem;
                }
            }
        }

        @include mobile {
            padding: 0.5rem;
            width: initial;
        }
    }
}