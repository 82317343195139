.login {
    &__wrapper {
        &-form {
            width: 30%;

            @include touch {
                width: 100%;
            }
        }
    }
    &__form {
        &-title {
            font-size: 2rem;
            font-weight: bold;

            @include mobile {
                font-size: 1.9rem;
            }
        }

        &-subtitle {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 13px;

            @include mobile {
                font-size: 1rem;
            }
        }

        &-inputtitle {
            font-size: 0.9rem;
            font-weight: bold;
            margin-bottom: 7px;
        }

        &-submit {
            display: flex;
            align-items: center;

            @include mobile {
                display: block;
            }
        }

        &-account {
            font-size: 0.8rem;
            font-weight: 500;
            margin-left: 20px;

            @include mobile {
                margin-top: 5px;
                margin-left: 0;
            }
        }

        &-link {
            color: $base-primary;
        }

        &-button {
            font-size: 0.9375rem;
            font-weight: bold;
            padding: 6px 30px;
        }
    }
}