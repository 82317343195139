.businesspoints {
    margin-bottom: 30px;

    &__list {
        display: flex;

        &-item {
            margin: 0 auto;
            display: flex;
            align-items: center;

            i {
                font-size: 30px;
                margin-right: 10px;
                color: $base-primary;
            }

            p {
                font-weight: 500;
            }

            &:last-of-type {
                margin: 0 0 0 auto;
            }

            &:first-of-type {
                margin: 0 auto 0 0;
            }
        }

        @include mobile {
            display: none;
        }
    }

    &__mobile {
        display: none;

        .businesspoints__item {
            display: flex;
            align-items: center;

            i {
                font-size: 30px;
                margin-right: 10px;
                color: $base-primary;
            }

            p {
                font-weight: 500;
            }
        }

        @include mobile {
            display: block;
        }
    }
}