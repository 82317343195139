.shoppingcartpopup__overlay {
    width: 100%;
    height: 100vh;
    background-color: rgba($base-secondary, 0.45);
    position: absolute;
    z-index: 98;
}

.shoppingcartpopup {
    position: absolute;
    height: 100vh;
    right: 0;
    z-index: 99; 
    background-color: $base-white;
    padding: 0 2.5rem;
    min-width: 13%;

    &__inner {
        position: relative;
        height: 100%;
    }

    &__heading {
        margin-bottom: 40px;
        padding-top: 40px;

        &-title {
            font-size: 1.3rem;
            font-weight: bold;
        }

        @include mobile {
            margin-bottom: 10px;
            padding-top: 1rem;
        }
    }

    &__buttons {
        position: absolute;
        bottom: -24px;
        width: 100%;
        padding-bottom: 40px;

        &-wrapper {
            display: flex;
            justify-content: center;
        }
    }

    &__button {
        text-align: center;
        &-btn {
            width: 80%;
            display: flex;
            justify-content: center;
            font-size: 1rem;
            padding: 5px 20px;
        }

        &-subbtn {
            color: $base-secondary;
            font-weight: 500;
            margin-top: 7px;
        }
    }

    &__cart {
        height: 76vh;
        overflow-y: auto;
        
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 1.5rem;
        }

        &-image {
            width: 75px;
            border-radius: 5px;
        }

        &-content {
            margin-left: 13px;

            .cart__content-title {
                font-size: 0.8rem;
                font-weight: bold;
            }

            .cart__content-price {
                font-weight: 500;
                display: flex;
                align-items: center;

                i {
                    font-size: 18px;
                    margin-right: 7px;
                }
            }
        }

        &-remove {
            margin: 0 10px 0 20px;
            i {
                font-size: 24px;
            }
        }
    }

    &__empty {
        text-align: center;
        margin-top: 5rem;

        &-icon {
            i {
                font-size: 75px;
            }
        }

        &-title {
            font-size: 1.1rem;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }

    @include mobile {
        padding: 0 1rem;
    }
}