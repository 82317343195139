.product {
    &__inner {
        display: flex;

        @include mobile {
            display: inline-block;
            width: 100%;
        }
    }

    &__image {

        &-img {
            width: 400px;
            height: 400px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 10px;

            @include mobile {
                width: 100%;
                height: 200px;
                border-radius: 5px;
            }
        }
        &-thumbnails {
            display: flex;
            margin-top: 15px;
        }
        &-thumbnail {
            width: 75px;
            height: 75px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px;
            cursor: pointer;
            margin-right: 10px;
            box-shadow: 0px 0px 29px 1px rgba(138,138,138,0.08);
            border: 2px solid transparent;
            transition: all .2s ease;

            &:hover {
                border-radius: 5px;
                border: 2px solid $base-primary;
            }

            @include mobile {
                width: 50px;
                height: 50px;
                margin-right: 5px;
            }
        }

        @include mobile {
            width: 100%;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        width: 100%;

        &-button {
            display: flex;
            align-items: center;

            .product__button-btn {
                padding: 4px 20px;

                span {
                    font-size: 0.8rem;
                }
            }

            .product__button-subbtn {
                margin-left: 2.0625rem;             
                cursor: pointer;
                transition: all .5s ease;

                span {
                    font-size: 0.8rem;
                    font-weight: 500;
                }

                &:hover {
                    opacity: 0.75;
                }

                @include mobile {
                    margin-left: 5px;
                }
            }

            @include mobile {
            }
        }
    }

    &__information {
        margin-left: 5rem;

        &-title {
            font-size: 2rem;
            font-weight: bold;
            margin: 0;

            @include mobile {
                font-size: 1.3rem;
            }
        }

        &-subtitle {
            font-size: 1rem;
            color: $base-primary;
            font-weight: 500;

            @include mobile {
                font-size: 1rem;
            }
        }

        &-description {
            font-size: 1rem!important;
            margin-top: 1.4375rem;
            margin-bottom: 1.5625rem;
            font-family: 'Montserrat', sans-serif;

            p {   
                font-size: 1rem!important;
                font-family: 'Montserrat', sans-serif!important;

                @include mobile {
                    font-size: 0.8rem!important;
                }
            }

            @include mobile {
                font-size: 1rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }

        @include mobile {
            margin-left: 0;
            margin-top: 1rem;
        }
    }

    &__tabs {
        &-list {
            margin: 2rem 0;

            @include mobile {
                display: flex;
            }
        }
        &-item {
            display: inline-block;
            margin-right: 40px;
            cursor: pointer;

            &-title {
                font-size: 1.7rem;
                font-weight: 500;

                @include mobile {
                    font-size: 1.3rem;
                }
            }
        }

        &-panel {
            padding: 0;
        }
    }
}

.activeProductTab {
    display: block;
}
.inactiveProductTab {
    display: none;
}

.activeProductNavigationTab {
    border-bottom: 3px solid $base-secondary;
}