.header {
  margin-bottom: 30px;

  &__inner {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $base-black;
    height: 375px;
    border-radius: 10px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    position: relative;

    &-overlay {
      position: absolute;
      left: 0;
      height: 375px;
      width: 100%;
      background-color: rgba($base-black, 0.4);
      border-radius: 10px;

      @include mobile {
        height: 250px;
      }
    }

    @include mobile {
      height: 250px;
      padding: 0 15px;
    }
  }

  &__heading {
    width: 30%;
    display: inline-block;
    z-index: 2;

    &-title {
      font-size: 1.75rem;
      font-weight: bold;
      color: $base-white;

      @include mobile {
        font-size: 21px;
      }
    }

    &-subtitle {
      font-size: 0.9375rem;
      font-weight: 500;
      color: $base-white;

      @include mobile {
        font-size: 12px;
      }
    }

    &-button {
      margin-top: 20px;
    }

    &-btn {
      padding: 6px 30px;
    }

    @include touch {
      width: 100%;
    }
  }
}