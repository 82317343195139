.shoppingcart {
    .completed {
        i, p {
            color: $base-primary;
        }
    }

    .deactived {
        pointer-events: none;
        cursor: default;
    }

    &__tabs {
        &-list {
            display: flex;
        }

        &-item {
            margin: 0 auto;
            text-align: center;
            padding: 10px 49px;
            cursor: pointer;
            transition: all .3s ease;

            &:first-of-type {
                margin: 0 auto 0 0;
            }

            i {
                color: $base-grey;
                font-size: 34px;

                @include mobile {
                    font-size: 27px;
                }
            }

            p {
                font-size: 1.1rem;
                color: $base-grey;
                font-weight: 500;
                margin-top: -13px;

                @include mobile {
                    display: none;
                }
            }

            @include mobile {
                padding: 5px 20px;
            }

            &:hover {
                background-color: $base-white;
                padding: 10px 49px;
                border-radius: 10px 10px 0 0;
                box-shadow: 0px -20px 22px 0px rgba(0,0,0,0.08);
            }
        }
        .activeNavigationTab {
            background-color: $base-white;
            padding: 10px 49px;
            border-radius: 10px 10px 0 0;
            box-shadow: 0px -20px 22px 0px rgba(0,0,0,0.08);
            i {
                color: $base-primary;
            }

            @include mobile {
                padding: 5px 20px;
            }
        }

        .react-tabs__tab-panel--selected {
            height: 575px;
            max-height: 575px;
            padding: 42px 42px 10px 42px;

        }

        &-panel {
            background-color: $base-white;
            box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.08);
        }
    }

    &__button {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 90px;
        box-shadow: 0px -9px 22px 0px rgba(126,126,126,0.08);
        display: flex;
        align-items: center;
        justify-content: center;

        &-inner {
            display: flex;
            align-items: center;

            @include mobile {
                display: block;
                margin: 0 auto;
                text-align: center;
            }
        }

        &-btn {
            font-size: 1rem;
            padding: 6px 42px;

            @include mobile {
                font-size: 0.8rem;
                padding: 4px 32px;
            }
        }

        &-totalprice {
            font-size: 1rem;
            color: $base-grey;
            font-weight: 500;
            margin-left: 15px;
            display: flex;
            align-items: center;

            span {
                color: $base-black;
                font-weight: 500;
                margin-left: 5px;
            }

            @include mobile {
                display: block;
                font-size: 0.8rem;
                margin: 5px 0 0 0;
                text-align: center;
            }
        }

        @include mobile {
        }
    }
}

.activeTab {
    height: 575px;
    max-height: 575px;
    border-radius: 0 0 10px 10px;
    padding: 42px 42px 8px 42px;

    @include mobile {
        padding: 1rem;
    }
}

.inactiveTab {
    display: none;
}