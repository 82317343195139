.confirmation {
    text-align: center;
    height: 50vh;
    &__message {
        i {
            font-size: 80px;

            @include mobile {
                font-size: 60px;
            }
        }

        &-title {
            font-size: 1.75rem;
            font-weight: bold;
        }

        &-subtitle {
            font-size: 1rem;
            padding: 0 10rem;
            margin: 1rem 0;

            @include mobile {
                padding: 0;
                margin: 0.5rem 0;
            }
        }
    }

    &__button {
        i {
            font-size: 24px;
        }

        &-btn {
            font-size: 1rem;
        }
    }

    @include mobile {
        height: initial;
    }
}