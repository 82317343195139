.site-header-bottom {
  width: 100%;
  background-color: $base-secondary;
  margin-bottom: 40px;

  &__wrapper {
    display: flex;
  }

  &__navigation {
    width: 100%;
    display: flex;
    transition: all 0.1s ease;
    align-items: center;

    .navigation__menu {
      display: flex;
      margin: 0;
      padding: 0;

      &-item {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0 35px 0 0;

        .navigation__menu-link {
          font-size: 0.9375rem;
          color: $base-white;
          text-decoration: none;
          font-weight: 500;
          padding: 20px 0;
          display: flex;
          align-items: center;
          // position: relative;
          transition: all .2s ease;

          i {
            font-size: 1.3125rem;
            margin-left: 2px;
          }
        }

        .open {          
          color: $base-primary;
          opacity: 1;
          visibility: visible;
          .navigation__menu-hover {
            opacity: 1;
            visibility: visible;
          }
        }

        .navigation__menu-beam {
          opacity: 0;
          visibility: hidden;
          width: 100%;
          height: 10px;
          position: absolute;
          background-color: $base-white;
          display: inline-block;
          top: 53px;
          border-radius: 10px 10px 0 0;
        }
      }

      &-hover {
        display: flex;
        flex-wrap: wrap;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 100%;
        left: 0;
        background-color: #fff;
        top: 63px;
        border-radius: 0 0 10px 10px;
        padding: 30px;
        transition: all .3s ease;

        .menu__hover {
          margin-bottom: 5px;

          &-sub {
            width: 21%;
          }

          &-list {
            list-style: none;
          }
          &-link {
            font-size: 1rem;
            color: $base-black;
            font-weight: 400;
            transition: all .3s ease;

            &:hover {
              color: $base-primary;
            }
          }
        }

        .menu__hover-title {
          font-weight: bold;
          color: $base-black;
          font-size: 1rem;
          margin-bottom: 10px;
        }
      }
    }
  }

  @include touch {
    display: none;
  }
}