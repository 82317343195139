.deliverydate {
    &__heading {
        margin-bottom: 20px;
        &-title {
            font-size: 1rem;
            font-weight: bold;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;

        .selectedDate {
            background-color: $base-primary;
            color: $base-white;
            border: 2px solid $base-primary;

            &:hover {
                color: $base-white;
            }
        }

        &-item {
            width: 20%;
            border: 2px solid #545C72;
            margin-right: 15px;
            margin-bottom: 15px;
            border-radius: 10px;
            padding: 1rem;
            color: #545C72;
            cursor: pointer;
            transition: all .5s ease;

            .item {
                &__heading {
                    font-weight: 500;
                }

                &__content {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 33px;
                    }

                    &-date {
                        margin-left: 10px;
                    }

                    &-day {
                        font-weight: 500;
                    }
                }
            }

            &:hover {
                border: 2px solid $base-primary;
                color: $base-secondary;
            }

            @include mobile {
                width: auto;
                margin-right: 0;
            }
        }

        @include mobile {
            display: block;
            height: 363px;
            overflow-x: auto;
        }
    }
}