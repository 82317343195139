
.choicesummary {
    &__inner {
        display: flex;
        margin-left: 3.8rem;
        width: 70%;

        @include mobile {
            width: 100%;
            margin-left: 0;
            display: block;
        }
    }

    &__image {
        width: 200px;
        margin-right: 2rem;

        &-img {
            width: 100%;
        }

        @include mobile {
            width: 40%;
            margin-right: 0;
        }
    }

    &__wrapper {
        display: flex;

        @include mobile {
            display: block;

        }
    }

    &__heading {
        margin-bottom: 1.675rem;
        margin-left: 3.8rem;
        display: flex;

        &-title {
            font-size: 1.6rem;
            font-weight: 500;

            @include mobile {
                font-size: 1.2rem;
            }
        }

        &-subtitle {
            @include mobile {
                display: none;
            }
        }

        &-number {
            background-color: $base-primary;
            border-radius: 50%;
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 1.6875rem;
                font-weight: 500;
            }
        }

        @include mobile {
            margin-bottom: 0.5rem;
            margin-left: 0;
            align-items: center;
        }
    }

    &__content {
        width: 50%;

        
        .test {
            &:last-of-type {
                display: none;
            }
        }

        &-title {
            font-size: 1.4rem;
            font-weight: 500;
            margin-bottom: 0.5rem;

            @include mobile {
                font-size: 1.1rem;
            }
        }

        @include mobile {
            width: 100%;
        }
    }

    &__button {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        align-items: center;

        .choicesummary__link  {
            display: flex;
            position: absolute;
            padding: 7px 25px;
            font-size: 0.9rem;

            span {
                @include mobile {
                    font-size: 0.7rem;
                }
            }

            @include mobile {
                padding: 5px 15px;
            }
        }

        @include mobile {
            display: block;
            width: initial;
            height: 43px;
            margin-top: 27px;
        }
    }
    &__values {
        display: flex;
        margin-bottom: 3px;

        &:last-of-type {
            display: none;
        }

        &-name {
            color: $base-grey;
            font-weight: 500;
            font-size: 1rem;
            margin-right: 40px;
            width: 100%;

            @include mobile {
                font-size: 0.8rem;
            }
        }

        &-value {
            color: $base-secondary;
            font-weight: 500;
            font-size: 1rem;
            text-align: left;
            width: 100%;
    

            @include mobile {
                font-size: 0.8rem;
            }
        }
    }

    &__price {
        display: flex;
        margin-top: 32px;

        &-title {
            color: $base-grey;
            font-size: 1.1rem;
            font-weight: 500;
            width: 100%;
            margin-right: 40px;

            @include mobile {
                font-size: 1rem;
            }
        }

        &-text {
            color: $base-secondary;
            font-size: 1.1rem;
            font-weight: 500;
            width: 100%;
            text-align: left;

            @include mobile {
                font-size: 1rem;
            }
        }
    }
}