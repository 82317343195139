.summary {
    display: flex;

    &__wrapper {
        width: 100%;
        display: flex;
        @include mobile {
            display: block;
            height: 479px;
            overflow-x: auto;
        }
    }

    &__products {
        width: 50%;
        height: 471px;
        overflow-y: auto;

        &-title {
            font-weight: bold;
            margin-bottom: 20px;
        }

        &-item {
            margin-bottom: 20px;
            display: flex;
        }

        &-image {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            margin-right: 20px;

            @include mobile {
                margin-right: 10px;
            }
        }

        &-content {
            .list__content-title {
                font-size: 1rem;
                font-weight: bold;
            }

            .list__content-specifications {
                font-size: 0.8rem;
                color: $base-primary;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .summary__values {
            display: flex;
            margin-bottom: 1px;
    
            &-name {
                color: $base-grey;
                font-weight: bold;
                font-size: 0.8rem;
                margin-right: 40px;
                width: 100%;
    
                @include mobile {
                    font-size: 0.8rem;
                }
            }
    
            &-value {
                color: $base-secondary;
                font-weight: bold;
                font-size: 0.8rem;
                text-align: left;
                width: 100%;
    
                @include mobile {
                    font-size: 0.8rem;
                }
            }
        }

        @include mobile {
            width: 100%;
            height: auto;
        }
    }

    &__information {
        width: 50%;

        &-inner {
            display: flex;

            @include mobile {
                display: block;
            }
        }

        @include mobile {
            width: 100%;
        }
    }
    &__content {
        width: 50%;

        &-title {
            font-weight: bold;
            margin-bottom: 10px;
        }

        @include mobile {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    &__deliverydate {
        margin-top: 40px;
        &-title {
            font-weight: bold;
        }

        &-message {
            span {
                font-weight: bold;
            }
        }
    }

    @include mobile {
        display: block;
    }
}