.categories-list {
  &__heading {
    margin-bottom: 15px;

    &-title {
      color: $base-secondary;
      font-weight: bold;
      font-size: 1.375rem;
      letter-spacing: 2px;

      @include mobile {
        font-size: 18px;
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;

    .categorie-item {
      height: 358px;
      margin-right: 12px;
      width: 19%;
      margin-bottom: 30px;
      position: relative;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }
      
      &__heading {
        &-title {
          font-size: 0.9375rem;
          font-weight: bold;
          color: $base-secondary;
          letter-spacing: 1px;
        }

        &-description {
          font-size: 0.75rem;
          font-weight: 400;
          color: $base-secondary;
          margin-top: 2px;
        }
      }

      &__content {
        @include mobile {
          display: inline-block;
          margin-left: 10px;
          width: 60%;
        }
      }

      &__button {
        margin-top: 15px;
        position: absolute;
        bottom: 0;
        cursor: pointer;

        @include mobile {
          margin-top: 5px;
        }
      }

      &__image {
        width: 100%;
        height: 236px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
        &-img {
          width: 100%;
          
          @include mobile {
            width: 140px;
            height: auto;
          }
        }

        @include mobile {
          width: 127px;
          height: 127px;
        }
      }

      @include mobile {
        display: flex;
        margin-right: 0;
        margin-bottom: 25px;
        width: 100%;
        height: 127px;;
      }

      @include tablet-only {
        margin-right: 15px;
      }
    }

    @include tablet-only {
      display: flex;
    }

    @include mobile {
      display: inline-block;
      width: 100%;
    }
  }
}