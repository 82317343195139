.choiceuploadpreview {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(14, 18, 30, 0.45);
    z-index: 999;

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $base-white;
        width: 80%;
        height: 90%;
        margin: 0 auto;
        border-radius: 10px;
        position: relative;
        padding: 1rem;

        &-image {
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &-close {
            justify-content: flex-end;
            display: flex;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            background-color: $base-white;
            z-index: 99;
            width: 130px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease;

            i {
                font-size: 30px;
            }

            span {
                font-size: 0.9rem;
                font-weight: 600;
                margin-left: 5px;
            }

            &:hover {
                background-color: $base-primary;
                color: $base-white;
            }
        }

        &-edit {
            justify-content: flex-end;
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
            background-color: $base-white;
            z-index: 99;
            width: 130px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease;

            i {
                font-size: 30px;
            }

            span {
                font-size: 0.9rem;
                font-weight: 600;
                margin-left: 5px;
            }

            &:hover {
                background-color: $base-primary;
                color: $base-white;
            }
        }
    }
}