.register {
    &__wrapper {
        &-form {
            width: 30%;

            @include touch {
                width: 100%;
            }
        }
    }
    &__inner {
        display: flex;

        @include touch {
            display: block;
        }
    }

    &__form {
        &-title {
            font-size: 2rem;
            font-weight: bold;
            
            @include mobile {
                font-size: 1.9rem;
            }
        }

        &-subtitle {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 13px;

            @include mobile {
                font-size: 1rem;
            }
        }

        &-inputtitle {
            font-size: 0.9rem;
            font-weight: bold;
            margin-bottom: 7px;
        }

        &-submit {
            display: flex;
            align-items: center;
            
            @include mobile {
                display: block;
            }
        }

        &-account {
            font-size: 0.8rem;
            font-weight: 500;
            margin-left: 20px;

            @include mobile {
                margin-top: 5px;
                margin-left: 0;
            }
        }

        &-link {
            color: $base-primary;
        }

        &-button {
            font-size: 0.9375rem;
            font-weight: bold;
            padding: 6px 30px;
        }
    }

    &__message {
        margin-left: 18.75rem;
        width: 30%;

        &-title {
            font-size: 1.2rem;
            font-weight: bold;

            @include mobile {
                font-size: 1.3rem;
            }
        }

        
        &-subtitle {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 23px;
            
            @include mobile {
                font-size: 1rem;
            }
        }

        .message {
            &__title {
                font-size: 1.1rem;
                font-weight: bold;

                @include mobile {
                    font-size: 1rem;
                }
            }

            &__list {
                &-item {
                    margin-bottom: 5px;
                }

                &-point {
                    font-size: 0.9rem;
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 30px;
                        color: $base-primary;
                        margin-right: 4px;

                        @include mobile {
                            font-size: 25px;
                        }
                    }

                    @include mobile {
                        font-size: 0.9rem;
                    }
                }
            }
        }

        @include touch {
            margin-left: 0;
            width: 100%;
            margin-top: 5rem;
        }
    }
}