.overview {
    &__list {

        &-mobile {
            display: none;

            .overview__list-product {
                display: flex;
            }

            @include mobile {
                display: block;
            }

            .overview__list-image {
                width: 100px;
                height: 100px;
            }

            .overview__list-actions {
                display: flex;
            }

            .overview__list-count {
                margin: 0;
            }
            .overview__list-remove {
                margin: 0 0 0 auto;
            }
        }

        &-desktop {
            display: block;

            @include mobile {
                display: none;
            }
        }

        &-inner {
            height: 442px;
            overflow-y: auto;
        }
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            @include mobile {
                display: block;
            }
        }

        &-image {
            width: 150px;
            border-radius: 10px;
            margin-right: 46px;

            @include mobile {
                width: 100px;
                margin-right: 15px;
            }
        }

        &-content {
            width: 30%;
            .list__content-title {
                font-size: 1.2rem;
                font-weight: bold;
                margin-bottom: 8px;

                @include mobile {
                    font-size: 0.9rem;
                }
            }

            .list__content-specifications {
                font-size: 0.8rem;
                color: $base-primary;
                font-weight: bold;
                margin-top: 6px;
                cursor: pointer;

                @include mobile {
                    font-size: 0.8rem;
                }
            }

            @include mobile {
                width: 100%;
            }
        }

        &-count {
            width: 20%;
            display: flex;
            align-items: center;
            margin: 0 auto;

            i {
                font-size: 22px;
                cursor: pointer;

                @include mobile {
                    font-size: 12px;
                }
            }

            span {
                font-size: 1rem;
                margin: 0 8px;
            }

            @include mobile {
                width: 100%;
            }
        }

        &-price {
            width: 20%;
            display: flex;
            align-items: center;
            margin: 0 auto;

            .list__content-price {
                font-size: 1rem;
                display: flex;
                align-items: center;
                font-weight: 500;

                i {
                    font-size: 24px;
                    margin-right: 8px;
                }
            }

            @include mobile {
                width: 100%;
            }
        }

        &-remove {
            i {
                font-size: 24px;
            }
        }
    }

    &__values {
        display: flex;
        margin-bottom: 1px;

        &-name {
            color: $base-grey;
            font-weight: bold;
            font-size: 0.8rem;
            margin-right: 40px;
            width: 100%;

            @include mobile {
                font-size: 0.8rem;
            }
        }

        &-value {
            color: $base-secondary;
            font-weight: bold;
            font-size: 0.8rem;
            text-align: left;
            width: 100%;

            @include mobile {
                font-size: 0.8rem;
            }
        }
    }

    &__empty {
        text-align: center;
        padding: 3rem 11rem 0 11rem;

        i {
            font-size: 100px;
            color: $base-primary;
        }

        &-title {
            font-size: 1.4rem;
            font-weight: bold;
            margin-top: -32px;
        }

        &-subtitle {
            font-size: 1rem;
            margin-top: 15px;
        }
    }
}