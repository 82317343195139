.choiceupload {
    .clicked {
        display: none;
    }
    &__inner {
        display: flex;
        margin-top: 1rem;
        margin-bottom: 10px;
    }
    &__form {

        &-wrapper {
            width: 100%;
            text-align: center;
        }

        &-library {
            padding-bottom: 15px;
            border-bottom: 2px solid $base-secondary;
            cursor: pointer;
        }

        &-hover {
            visibility: hidden;
            opacity: 0;
            height: 274px;
            width: 275px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(14, 18, 30, 0.45);
            position: absolute;
            color: $base-white;
            font-size: 26px;
            border-radius: 10px;
            cursor: pointer;
            transition: all .3s ease;
        }

        &-input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        &-link {
            color: $base-primary;
            font-weight: 600;
            cursor: pointer;
        }

        &-text {
            font-size: 1rem;
            color: $base-secondary;
            font-weight: 500;
        }

        &-filename {
            font-size: 0.9rem;
            color: $base-grey;
            font-weight: 500;
            margin: 5px 0;
        }

        &-empty {
            width: 275px;
            border: 2px dashed #D1D1D1;
            border-radius: 10px;
            height: 275px;
            text-align: center;
            display: flex;
            align-items: center;
            padding: 1rem;
            i {
                font-size: 45px;
            }
        }

        &-full {
            width: 275px;
            border: 2px dashed #D1D1D1;
            border-radius: 10px;
            height: 275px;
            text-align: center;
            display: flex;
            align-items: center;
            padding: 1rem;
            i {
                font-size: 45px;
            }
        }

        &-status {
            width: 275px;
            border: 2px dashed #D1D1D1;
            border-radius: 10px;
            height: 275px;
            text-align: center;
            display: flex;
            align-items: center;
            padding: 1rem;

            .status__title {
                font-size: 1rem;
                font-weight: bold;
            }

            .status__content {
                color:$base-primary;
                font-weight: bold;
                font-size: 1rem;
            }
        }

        &-image {
            width: 275px;
            border: 2px solid $base-secondary;
            border-radius: 10px;
            height: 275px;
            text-align: center;
            display: flex;
            align-items: center;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            &:hover {
                .choiceupload__form-hover {
                    visibility: visible;
                    opacity: 1;
                    width: 274px;
                    height: 275px;
                }
            }
        }

        &-img {
            border-radius: 10px;
            position: relative;
        }

        &-btn {
            font-size: 1rem;
            padding: 2px 39px;
            margin-top: 10px;
            span {
                i {
                    font-size: 24px;
                }
            }
        }

        &-error {
            margin-top: 10px;
            font-weight: bold;
        }

        &-actions {
            display: flex;
            margin-top: 10px;

            i {
                font-size: 18px;
                color: $base-white;
            }
        }

        &-delete {
            margin: 0 auto 0 0;
            background-color: $base-primary;
            padding: 5px 14px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }

        &-rotate {
            margin: 0 auto;
            border: 2px solid $base-secondary;
            padding: 3px 10px;
            border-radius: 5px;      
            transition: all .3s ease;
            cursor: pointer;
            
            i {
                color: $base-secondary;
            }

            &:hover {
                background-color: $base-secondary;
                i {
                    color: $base-white;
                }
            }
        }

        &-popuptrigger {
            margin: 0 auto;
            border: 2px solid $base-secondary;
            padding: 3px 10px;
            border-radius: 5px;      
            transition: all .3s ease;
            cursor: pointer;

            i {
                color: $base-secondary;
            }

            &:hover {
                background-color: $base-secondary;
                i {
                    color: $base-white;
                }
            }
        }
    }

    &__preview {
        width: 40%;
        margin-left: 2rem;

        &-heading {
            .preview__heading {
                &-title {
                    font-size: 1.5rem;
                    font-weight: bold;
                }

                &-subtitle {
                    font-size: 1rem;
                }
            }
        }

        .preview__image {
            img {
                max-width: 311px;
            }
        }
    }

    &__cropper {
        .crop-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 80px;
          }
        &-controls {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 50%;
            transform: translateX(-50%);
            height: 80px;
            display: flex;
            align-items: center;

            .MuiSlider-root {
                color: $base-primary;
            }

            .MuiSlider-thumb:hover {
                box-shadow: 0px 0px 0px 8px rgba(183,167,103,0.16);
            }
        }
    }
}