@import "react-slideshow-image/dist/styles.css";

html {
  margin: 0;
  padding: 0;
  background-color: $base-background!important;
  font-family: $font-family;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}
  
body {
  
  position: relative;
  margin: 0;
  min-height: 100%;
  margin: 0;
  padding: 0;
  color: $base-secondary!important;
  font-family: $font-family!important;
  overflow: hidden;
}

input, textarea {
  box-sizing: border-box;
}

ul {
  list-style: initial;
  list-style-position: inside;
}


@media only screen and (min-width: 1540px) {
  .container {
    max-width: 1244px!important;
  }
}

.container {
  @include touch {
    padding: 0 1rem;
  }
}

// Main Styling
.main {
  min-height: 50vh;
  margin-top: 175px;

  @include touch {
    margin-top: 100px;
  }
}

// Navbar styling
.navbar {
  z-index: 998;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: all .5s ease;
}

// Styling for basic button
.btn {
  padding: 2px 22px;
  border-radius: 5px;
  transition: all .5s ease;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;

  i {
    font-size: 18px;
    margin-left: 3px;
  }

  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &--primary {
    background-color: $base-primary;
    border: 1px solid $base-primary;
    color: $base-white;

    &:hover { 
      background-color: transparent;
      border: 1px solid $base-primary;
      color: $base-primary;
    }
  }
  &--secondary {
    background-color: transparent;
    border: 1px solid $base-secondary;
    
    &:hover { 
      background-color: $base-secondary;
      border: 1px solid $base-secondary;
      color: $base-white;
    }
  }
}