.deliveryaddress {

    &__wrapper {
        display: flex;
        height: 443px;
        overflow-y: auto;

        @include mobile {
            display: block;
        }
    }

    &__inner {
    }

    &__title {
        font-size: 1rem;
        font-weight: bold;
    }

    &__subtitle {
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 5px;
    }

    &__button {
        width: 50%;
        display: flex;
        align-items: center;
    }

    &__information {
        width: 50%;

        @include mobile {
            width: 100%;
        }
    }

    &__form {
        &-subtitle {
            margin-bottom: 10px;
            font-size: 0.8rem;
        }

        &-group {
            display: flex;

            @include mobile {
                display: initial;
            }
        }

        &-small {
            max-width: 120px;
        }

        &-input {
            flex: 1;
            margin-right: 25px;
        }
        &-medium {
            flex: initial;
        }


        &-phone {
            flex: initial;
        }

        &-inputtitle {
            font-size: 0.8rem;
        }
    }

    &__summary {
        margin: 0 auto 0 1rem;

        &-error {
            font-style: italic;
            font-weight: 500;
            margin-bottom: 5px;
            font-size: 1rem;
        }

        &-content {
            display: flex;
        }

        &-inner {
            font-size: 0.9rem;
        }

        &-delivery {
            margin-right: 2rem;
        }

        &-title {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 16px;
        }
    }

    &__different {
        margin-bottom: 1rem;
        &-title {
            font-size: 1rem;
            font-weight: bold;
        }

        &-subtitle {
            font-size: 0.8rem;
        }

        &-btn {
            padding: 9px 20px;
            font-size: 0.8rem;
        }
    }
}