.choiceuploadpopup{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(14, 18, 30, 0.45);
    z-index: 999;

    &__inner {
        justify-content: center;
        align-items: center;
        background-color: $base-white;
        width: 80%;
        height: 90%;
        margin: 0 auto;
        border-radius: 10px;
        position: relative;
        padding: 1rem;
        overflow-y: auto;

        &-image {
            width: 100%;
            height: 500px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &-close {
            justify-content: flex-end;
            display: flex;
            position: absolute;
            right: 0;
            top: 0;
            margin-right: 10px;
            cursor: pointer;

            i {
                font-size: 30px;
            }
        }

        @include mobile {
            width: 90%;
        }
    }

    &__categories {
        .choiceuploadcategories__list {
            display: flex;
            
            .selectedorientation {
                border: 2px solid $base-secondary;
                color: $base-white;
                background-color: $base-secondary;
            }

            &-item {
                border: 2px solid $base-secondary;
                padding: 8px 22px;
                color: $base-secondary;
                font-weight: 600;
                font-size: 0.9rem;
                border-radius: 5px;
                margin-right: 15px;
                cursor: pointer;
                transition: all .3s ease;

                &:hover {
                    border: 2px solid $base-secondary;
                    color: $base-white;
                    background-color: $base-secondary;
                }

                @include mobile {
                    margin: 10px 0;
                }
            }

            @include mobile {
                display: block;
            }
        }
    }

    &__colors {
        position: relative;
        display: flex;
        align-items: center;
        width: 9%;

        &-heading {
            display: flex;
            align-items: center; 
            cursor: pointer;
            color: $base-secondary;
            font-weight: 600;
            font-size: 0.9rem;
            cursor: pointer;

            i {
                font-size: 20px;
            }
        }

        &-options {
            width: 150px;
            position: absolute;
            top: 36px;
            display: flex;
            flex-wrap: wrap;
            background-color: $base-white;
            padding: 10px;
            border-radius: 5px;
            box-shadow: 0px 0px 22px 0px rgba(139, 139, 139, 0.1);

            .noneselected {
                color: $base-primary;
            }

            .colorsoptions__list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 5px;

                &-none {
                    cursor: pointer;
                }


                &-item {
                    width: 25px;
                    height: 25px;
                    margin: 0 5px 5px 0;
                    border-radius: 2px;
                    cursor: pointer;
                }

                .active {
                    border: 2px solid $base-primary;
                }
            }
        }

        @include mobile {
            width: 100%;
        }
    }

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &-item {
            width: 200px;
            height: 250px;
            margin: 0 15px 50px 15px;
        }

        &-inner {
            height: inherit;
        }

        &-picture {
            width: 100%;
            height: 80%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        &-text {
            display: none;
            margin-top: 10px;
            text-align: center;
            font-size: 0.7rem;
            color: $base-grey;

            a {
                color: $base-grey;
                font-weight: 500;

                &:hover {
                    color: $base-black;
                }
            }
        }

        .selected {
            cursor: pointer;
            border: 2px solid $base-secondary;
            
            .choiceuploadpopup__list-text {
                display: block;
            }
        }
    
        .deselected {
            cursor: pointer;

            &:hover {
                border: 2px solid $base-secondary;

                .choiceuploadpopup__list-text {
                    display: block;
                }
            }
        }
    }

    &__heading {
        padding: 0 15px;
        margin-bottom: 15px;

        &-title {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 15px;
        }

        &-filters {
            display: flex;
            margin-bottom: 25px;

            @include mobile {
                display: block;
            }
        }
    }

    &__search {
        position: relative;
        width: 40%;
        margin: 0 auto 0 0;

        &-wrapper {
            width: 100%;

            i {
                position: absolute;
                padding: 8px 16px;
                text-align: left;
                color: $base-grey;
                font-size: 16px;
            }

            .auth-input {
                padding: 8px 14px 8px 42px;
                margin-bottom: 0;
            }
        }
        
        @include mobile {
            width: 100%;
        }
    }
}