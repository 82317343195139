.footer {
  // position: absolute;
  // right: 0;
  // bottom: 0;
  // left: 0;
  background-color: $base-white;
  margin-top: 30px;
  box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.07);
  
  &__content {
    display: flex;
    padding: 95px 0;

    .navigation__list {
      margin: 0 auto;

      &:first-of-type {
        margin: 0 auto 0 0;

        @include mobile {
          margin-bottom: 20px;
        }
      }
      &-item {
        list-style: none;
        margin-bottom: 5px;
      }

      &-svg {
          width: 50px;
      }

      &-secondtitle {
        margin-top: 10px;
      }

      &-title {
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 10px;

        @include mobile {
          font-size: 18px;
        }
      }

      &-subtitle {
        font-size: 0.875rem;
        font-weight: 400;
        color: $base-secondary;
      }

      &-link {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 400;
        color: $base-secondary;
        transition: all 0.5s ease;

        i {
          font-size: 25px;
          margin-right: 14px;
        }

        &:hover {
          color: $base-primary;
        }

        @include mobile {
          font-size: 14px;
        }
      }

      &-actionlink {
        color: $base-primary;
        font-weight: 400;
        transition: all 0.5s ease;

        &:hover {
          opacity: .7;
        }
      }

      @include mobile {
        margin-bottom: 20px;
      }
    }

    @include mobile {
      display: inline-block;
      padding: 50px 1rem;
    }
  }

  &__bar {
    background-color: $base-primary;
    color: $base-white;

    &-inner {
      display: flex;
      padding: 18px 0;

      @include mobile {
        display: inline-block;
        padding: 18px 0;
        width: 100%;
        text-align: center;
      }
    }

    &-copyright {
      font-size: 0.875rem;
      font-weight: 500;

      @include mobile {
        margin-bottom: 10px;
      }
    }

    &-navigation {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;

      .footer__bar-link {
        font-size: 0.875rem;
        font-weight: 500;
        color: $base-white;
        transition: all .5s ease;

        &:first-child {
          margin: 0 50px 0 0;

          @include mobile {
            margin: 0 0 10px 0;
          }
        }

        &:hover {
          opacity: .75;
        }

        @include mobile {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-bottom: 10px;
        }
      }

      @include mobile {
        position: relative;
        display: inline-block;
      }
    }
  }
}