.smallloading {
    .loading__inner {
        display: initial;
    }
    .ldio-5q6n2yu62z div {
        position: absolute;
        width: 15px;
        height: 15px;
        border: 3px solid $base-secondary;
        border-top-color: transparent;
        border-radius: 50%;
      }
      .ldio-5q6n2yu62z div {
        animation: ldio-5q6n2yu62z 1s linear infinite;
        top: 26px;
        left: 24px;
      }
      .loadingio-spinner-rolling-wtp2uby56tg {
        width: 50px;
        height: 50px;
        display: inline-block;
        overflow: hidden;
      }
      .ldio-5q6n2yu62z {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
      }
}

.loading {
    &__inner {
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  @keyframes ldio-5q6n2yu62z {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-5q6n2yu62z div {
    position: absolute;
    width: 44px;
    height: 44px;
    border: 6px solid $base-secondary;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-5q6n2yu62z div {
    animation: ldio-5q6n2yu62z 1s linear infinite;
    top: 100px;
    left: 100px
  }
  .loadingio-spinner-rolling-wtp2uby56tg {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
  }
  .ldio-5q6n2yu62z {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-5q6n2yu62z div { box-sizing: content-box; }
  