.form-group {
    display: flex;
}

.auth-input {
    margin: 0 0 20px 0;
    border: 2px solid $base-grey;
    width: 100%;
    padding: 8px 14px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    color: $base-grey;
    box-shadow: none;

    &:active, &:focus {
        border: 2px solid $base-primary;
    }

    &-unit {
        position: absolute;
        right: 12px;
        top: 6px;
        font-size: 1rem;
        color: $base-grey;
        font-weight: 500
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  
  
  